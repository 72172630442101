
import React, { useState } from "react";
import axios from "axios";

export class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });

  }

  handleLogin(event) {
    event.preventDefault();
    console.log(this.state);
    this.login();
  }

  login = async () => {
    const response = await axios.get("https://memory.taikonaut.com/.well-known/ai-plugin.json");
    console.log(response.data)
  }

  render() {
    return (
      <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={this.handleLogin}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                name="email"
                type="email"
                className="form-control mt-1"
                placeholder="Enter email"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                name="password"
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
                value={this.state.password}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
            <p className="text-center mt-2">
              Forgot <a href="#">password?</a>
            </p>
          </div>
        </form>
      </div>
    )
  }
}